.nav-text {
    width: 300px;
    height: 50px;
    background-color: #eeeeee;
    justify-content: start;
    align-items: center;
    list-style: none;
    border-radius: 4px;

}

.nav-text a {
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    min-width: 230px;
    color: #1A2B52;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;

}

.nav-text a:hover {
    background-color: #2d800c;
    color: #ffffff;
    border-radius: 4px;
}

.active {
    background-color: #2d800c;
    color: #ffffff;
}

.active a {
    color: #ffffff;
}