@import '~react-agenda/build/styles.css';
@import '~react-datetime/css/react-datetime.css';
.ContainerHome {
    overflow: hidden;
    width: 100vw;
    height: 83vh;
    background-color: #F0F0F0;
    align-items:flex-start;
    padding-inline: 20px;
    padding-block: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.ContainerHomelive {
    overflow: hidden;
    width: 100vw;
    height: 83vh;
    background-color: #F0F0F0;
    align-items:flex-start;
    padding-inline: 20px;
    padding-block: 20px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.ContainerTableNav{
    width: 100%;
    height: 80px;
    flex-direction: row;
    border-radius: 10px;
    margin-bottom: 10px;
    align-items: center;
    padding-inline: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
}
.ContainerTableNav button {
    height: 60px;
    background-color: #1A2B52;
}

.ContainerTable{
    width: 100%;
    overflow: hidden;
    padding-inline: 10px;
    height: 100%;
    border-radius: 10px;
    overflow: auto;
    background-color: #fff;
}

.container {
    border: 2px solid #dedede;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
  }
  
  /* Darker chat container */
  .darker {
    border-color: #3c5270;
    color: #fff;
    background-color: #3c5270;
  }
  
  /* Clear floats */
  .container::after {
    content: "";
    clear: both;
    display: table;
  }
  
  /* Style images */
  .container img {
    float: left;
    max-width: 60px;
    width: 100%;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  /* Style the right image */
  .container img.right {
    float: right;
    margin-left: 20px;
    margin-right:0;
  }
  
  /* Style time text */
  .time-right {
    float: right;
    color: #211c6e;
  }
  
  /* Style time text */
  .time-left {
    float: left;
    color: #999;
  }