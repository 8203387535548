.Header {
    width: 100vw;
    height: 65px;
    background-color: #1A2B52;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 30px;
}

.Header_1 {
    width: 30vw;
    height: 50px;
    background-color: #1A2B52;
    display: flex;
    align-items: center;
    justify-content:flex-start;
}

.Header_2 {
    width: 50vw;
    height: 50px;
    background-color: #1A2B52;
    display: flex;
    align-items: center;
    justify-content:flex-start;
}

.Header_3 {
    width: 10vw;
    height: 50px;
    background-color: #1A2B52;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header_4 {
    width: 18vw;
    height: 50px;
    background-color: #1A2B52;
    display: flex;
    align-items: center;
    justify-content:flex-end;
}